// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.png";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
import cpp from "./assets/techstack/cpp.jpg";
import ex from "./assets/techstack/ex.png";
import firebase from "./assets/techstack/firebase.jpg";
import mongo from "./assets/techstack/mongo.png";
import nodejs from "./assets/techstack/nodejs.png";
import c from "./assets/techstack/Untitled (12).jpg"; 
import java from "./assets/techstack/Untitled (13).jpg";
import php from "./assets/techstack/php.png";
import sql from "./assets/techstack/sql.png";


// Porject Images
import projectImage1 from "./assets/projects/certificate generater.png";
import projectImage2 from "./assets/projects/download imag.png";
import projectImage3 from "./assets/projects/code editor.png";
import projectImage4 from "./assets/projects/word.png";
import projectImage5 from "./assets/projects/coming soon.png";



// Certificates
import Certificates1 from "./assets/certificates/thinkerrs certificate.png";
import Certificates2 from "./assets/certificates/Web Development Certificate_page-0001.jpg";
import Certificates3 from "./assets/certificates/Digi20.jpg";
import Certificates4 from "./assets/certificates/uber.jpg";
import Certificates5 from "./assets/certificates/Certificate_2nd Runner up_page-0002.jpg";
import Certificates6 from "./assets/certificates/full stack web dev.jpg";


// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Ranjan Kumar",
  tagline: "Full Stack Developer",
  img: profile,
  about: `I am a highly motivated and results-oriented 4th year Computer Science and Engineering student at Heritage Institute of Technology, Kolkata with a strong passion for full-stack web development. I possess a proven ability to leverage my technical skills and knowledge to design, develop, and implement complex web applications.

I am eager to leverage my skills and knowledge to contribute to a challenging and rewarding role within the software development industry.

<b>Beyond Coding</b>
${"ih "}
In addition to my technical expertise, I hold a deep appreciation for the art of poetry. My creative side has found expression in poems published in both local and American publications.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/iamranjansah",
  github: "https://www.github.com/iamranjansah",
  twitter: "https://twitter.com/iamranjansah",
  instagram: "https://www.instagram.com/iamranjansah",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Full Stack Web Developer",
    Company: `CoolieWale.in`,
    Location: "Remote",
    Type: "Internship",
    Duration: "June 2024 - July 2024",
  },

  {
    Position: "Software Development Intern",
    Company: `Thinkers Corporation Private Limited`,
    Location: "Remote",
    Type: "Internship",
    Duration: "Oct 2023 - April 2024",
  },

  {
    Position: " Frontend Developer & Digital Marketing Intern",
    Company: `DoctorsApp`,
    Location: "Remote",
    Type: "Internship",
    Duration: "June 2023 - August 2023",
  },
  {
    Position: "Digital Marketing Specialist",
    Company: `Digi2o.com`,
    Location: "Remote",
    Type: "Internship",
    Duration: "June 2023 - July 2023",
  },

];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Computer Science & Engineering",
    Company: "Heritage Institute of Technology",
    Location: "Kolkata",
    Type: "7.2 CGPA",
    Duration: "Jun 2021 - Present",
  },
  {
    Position: "Intermediate in Science [Math]",
    Company: `L. S College, Muzaffarpur]`,
    Location: "Muzaffarpur",
    Type: "83%",
    Duration: "May 2018 - June 2020",
  },
  {
    Position: "Web Development Bootcamp",
    Company: `Udemy`,
    Location: "Online",
    Type: "100%",
    Duration: "Mar 2023 - April 2023",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
  java: java,
  c:c,
  nodejs:nodejs,
  firebase:firebase,
  ex:ex,
  cpp:cpp,
  mongo: mongo,
  sql:sql,
  php:php,

};


// Tech Stack and Tools
export const Certificates = {
  Certificates1: Certificates1,
  Certificates2: Certificates2,
  Certificates3: Certificates3,
  Certificates4: Certificates4,
  Certificates5: Certificates5,
  Certificates6: Certificates6,



};
// Enter your Project Details here
export const projectDetails = [
  {
    title: "Certificate Generator",
    image: projectImage1,
    description: `This is simple project which is used to generate the certificate. In the input field you can put Name, Company Name and name of the Courses`,
    techstack: "HTML, CSS, JS, Reactjs, Nodejs",
    previewLink: "https://certificate-generator-by-ranjan.netlify.app/",
    githubLink: "https://github.com/iamranjansah/certificate-generator",
  },
  {
    title: "Image Generate and Download",
    image: projectImage2,
    description: `This Image generator is used for free image, user can generate image accordingly and the can use.`,
    techstack: "HTML, CSS, JAVASCRIPT, REACTJS, NODEJS, AND Unsplash API.",
    previewLink: "https://img-generator-by-ranjan-kumar.netlify.app/",
    githubLink: "https://github.com/iamranjansah/img-generator",
  },
  {
    title: "Code Editor - HTML, CSS, JS",
    image: projectImage3,
    description: `By the using of this code editor you can run your code easily, without using any code editor like Vscode, codeblock etc...`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://code-editor-i19s.vercel.app/",
    githubLink: "https://github.com/iamranjansah/CodeEditor",
  },
  {
    title: "Word Play",
    image: projectImage4,
    description: `By the using of this tool you can Convert to Uppercase, Clear Text, Convert to Lowercase, Remove Special Character and ETC...`,
    techstack: "HTML/CSS, JavaScript, Reactjs",
    previewLink: "https://word-play-three.vercel.app/",
    githubLink: "https://github.com/iamranjansah/WordPlay",
  },
  {
    title: "Coming Soon",
    image: projectImage5,
    description: `Coming Soon`,
    techstack: "Soon...",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
 
];

// Enter your Contact Details here
export const contactDetails = {
  email: "iamranjansahh@gmail.com",
  phone: "+91 7324002432",
};
