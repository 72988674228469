import React from "react";
import { techStackDetails } from "../Details";
import "../../src/index.css"

function Technologies() {
  const {
    html,
    css,
    js,
    react,
    redux,
    tailwind,
    bootstrap,
    sass,
    vscode,
    git,
    github,
    npm,
    postman,
    figma,
    java, 
    c,
    nodejs,
    mongo,
    firebase,
    ex,
    cpp,
    php,
    sql,
  } = techStackDetails;
  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        <p className="text-content py-2 lg:max-w-3xl">
          Technologies I've been working with recently
        </p>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-7 items-center gap-10 pt-6">
        <img className="tech-ani" src={html} title="html" alt="" />
        <img className="tech-ani" src={css} title="CSS" alt="" />
        <img className="tech-ani" src={tailwind} title="Tailwind CSS" alt="" />
        <img className="tech-ani" src={bootstrap} title="Bootstrap" alt="" />
        <img className="tech-ani" src={sass} title="SASS" alt="" />
        <img className="tech-ani" src={js} title="JavaScript" alt="" />
        <img className="tech-ani" src={react} title="React" alt="" />
        <img className="tech-ani" src={redux} title="Redux" alt="" />
        <img className="tech-ani" src={nodejs} title="nodejs" alt="" />
        <img className="tech-ani" src={php} title="php" alt="" />
        <img className="tech-ani" src={ex} title="ex" alt="" />
        <img className="tech-ani" src={mongo} title="mongo" alt="" />
        <img className="tech-ani" src={sql} title="sql" alt="" />
        <img className="tech-ani" src={firebase} title="firebase" alt="" />
        <img className="tech-ani" src={java} title="java" alt="" />
        <img className="tech-ani" src={c} title="c" alt="" />
        <img className="tech-ani" src={cpp} title="cpp" alt="" />
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img className="tech-ani" src={vscode} title="Visual Studio Code" alt="" />
        <img className="tech-ani" src={git} title="Git" alt="Git" />
        <img className="tech-ani" src={github} title="Github" alt="Github" />
        <img className="tech-ani" src={figma} title="Figma" alt="Figma" />
        <img className="tech-ani" src={npm} title="NPM" alt="NPM" />
        <img className="tech-ani" src={postman} title="Postman" alt="Postman" />
      </section>
    </main>
  );
}

export default Technologies;
