import React, { useState } from 'react';
import Work from "../Components/Work";
import "../../src/index.css"
import { Certificates } from "../Details";
import { personalDetails, workDetails, eduDetails } from "../Details";

function About() {

  const {
    Certificates1,
    Certificates2,
    Certificates3,
    Certificates4,
    Certificates5,
    Certificates6,
   


  
  
  } = Certificates;

  const [fullscreenImage, setFullscreenImage] = useState(null);
  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          About Me
        </h1>
        <p className="text-content py-8 lg:max-w-3xl">I am a highly motivated and results-oriented <span className="bg-color"> 4th year Computer Science and Engineering student at Heritage Institute of Technology, Kolkata with a strong passion for full-stack web development.</span> I possess a proven ability to leverage my technical skills and knowledge to design, develop, and implement complex web applications.

I am eager to leverage my skills and knowledge to contribute to a challenging and rewarding role within the software development industry.
<p>
<b>Beyond Coding:</b>

In addition to my technical expertise, I hold a deep appreciation for the art of poetry. My creative side has found expression in poems published in both local and American publications.</p></p>
      </section>
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Work Experience
        </h1>
        {React.Children.toArray(
          workDetails.map(({ Position, Company, Location, Type, Duration }) => (
            <Work
              position={Position}
              company={Company}
              location={Location}
              type={Type}
              duration={Duration}
            />
          ))
        )}
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Education
        </h1>
        {React.Children.toArray(
          eduDetails.map(({ Position, Company, Location, Type, Duration }) => (
            <Work
              position={Position}
              company={Company}
              location={Location}
              type={Type}
              duration={Duration}
            />
          ))
        )}
      </section>



      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Certificates
        </h1>
       
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center gap-10 pt-6">

      <img src={Certificates6} 
       onClick={() => openFullscreen(Certificates6)}
       className="certificate-image"

         title="Certificate" alt="" />
        <img src={Certificates1} 
       onClick={() => openFullscreen(Certificates1)}
       className="certificate-image"

         title="Certificate" alt="" />
      
      <img src={Certificates2} 
       onClick={() => openFullscreen(Certificates2)}
       className="certificate-image"

         title="Certificate" alt="" />

            
      <img src={Certificates3} 
       onClick={() => openFullscreen(Certificates3)}
       className="certificate-image"

         title="Certificate" alt="" />

<img src={Certificates4} 
       onClick={() => openFullscreen(Certificates4)}
       className="certificate-image"

         title="Certificate" alt="" />

<img src={Certificates5} 
       onClick={() => openFullscreen(Certificates5)}
       className="certificate-image"

         title="Certificate" alt="" />
      
      
      </section>
      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img src={fullscreenImage} alt="Full screen" className="fullscreen-image" />
        </div>
      )}

       
   
      
    </main>
  );
}

export default About;
